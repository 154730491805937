import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// 主要用于配合vscode i18nn ally插件的提示。此功能仅用于路由和菜单。请在其他地方使用 vue-i18n 的 useI18n
export const t = (key: string) => key;

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Ayaey',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/register',
    name: '注册',
    meta: {
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/register/index.vue')
  },
  {
    path: '/treaty',
    name: '服务条款',
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/treaty/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
