import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './styles/index.scss'
import { setupAntd } from './plugins/antd'

// if (process.env.NODE_ENV === 'development') {
// eslint-disable-next-line
// const { mockXHR } = require('../mock')
// mockXHR()
// }
const app = createApp(App)
setupAntd(app)
app.use(router).mount('#app')
